body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.popover {
	border: 0px !important;
	background: #ffffff !important;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
	border-radius: 8px !important;
}
.Profile-Popover .arrow.popover-arrow {
	left: 60px !important;
}
.arrow.popover-arrow::before {
	border: 0px !important;
	background: #ffffff !important;
}
/* Toast Styles */
.Toastify__toast{
	border-radius: 6px !important;
	font-family: 'montserrat-medium' !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	height: unset !important;
	min-height: unset !important;
	max-width: unset !important;
	padding: 12px !important;
}
.Toastify__toast-icon{
	width: 18px !important;
}
.Toastify__close-button{
	display: none;
}
.Toastify__close-button > svg{
	width: 14px !important;
	height: 14px !important;
}
.Toastify__toast-container{
	width: fit-content !important;
	min-width: 200px !important;
	max-width: 400px !important;
}