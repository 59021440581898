@font-face {
	font-family: 'montserrat-light';
	src: url('./../font/Montserrat-Light.ttf'); /* IE9 Compat Modes */
	src: url('./../font/Montserrat-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'montserrat-regular';
	src: url('./../font/Montserrat-Regular.ttf'); /* IE9 Compat Modes */
	src: url('./../font/Montserrat-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'montserrat-medium';
	src: url('./../font/Montserrat-Medium.ttf'); /* IE9 Compat Modes */
	src: url('./../font/Montserrat-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'montserrat-semi-bold';
	src: url('./../font/Montserrat-SemiBold.ttf'); /* IE9 Compat Modes */
	src: url('./../font/Montserrat-SemiBold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'montserrat-bold';
	src: url('./../font/Montserrat-Bold.ttf'); /* IE9 Compat Modes */
	src: url('./../font/Montserrat-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'segoeui-bold';
	src: url('./../font/SegoeUI-Bold.ttf'); /* IE9 Compat Modes */
	src: url('./../font/SegoeUI-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'poppins-regular';
	src: url('./../font/Poppins-Regular.ttf');
	src: url('./../font/Poppins-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'poppins-bold';
	src: url('./../font/Poppins-Bold.ttf');
	src: url('./../font/Poppins-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'poppins-medium';
	src: url('./../font/Poppins-Medium.ttf');
	src: url('./../font/Poppins-Medium.ttf') format('truetype');
}
