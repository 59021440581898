@import './sidebar';
@import './helper';
@import './fonts';
@import './form';
@import './charts';
@import './table';
@import './button';
@import './typography';
@import './reactstrapdoc';
@import './imageUploader.scss';

body {
	overflow: hidden;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
	margin-bottom: 0;
	font-family: 'montserrat-regular';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.setting-button {
	position: fixed;
	top: 50%;
	z-index: 3;
	right: 0;
	// background-color: #241d3b !important;
	box-shadow: none;
	font-size: 20px !important;
	padding: 10px !important;
}

.arrow-sidebar {
	top: 15px;
	position: absolute;
	right: 5px;
	font-size: 14px !important;
	z-index: 1;
	cursor: pointer;
}

.Page-title {
	display: flex;
	align-items: center;

	.title-icon {
		width: 45px;
		height: 45px;
		font-weight: 600;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		font-family: 'montserrat-bold';
	}

	.title {
		font-size: larger;
		font-weight: 600;

		span {
			font-family: 'montserrat-bold';
		}
	}
}

.error-back {
	position: fixed;
	overflow: auto;
	top: 0;
	bottom: 0;
}

.error-container {
	box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
		0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
	text-align: center;
	padding: 20% 0;
	width: 100%;
	margin: 10% 0;

	.error-heading {
		font-size: 112px !important;
		font-weight: 300;
		line-height: 1 !important;
		letter-spacing: -0.04em !important;
		color: white;
	}

	.error-sub-title {
		font-size: 16px !important;
		font-weight: 400;
		color: white;
		padding: 20px 0;
	}

	.goHome {
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
			0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
		background-color: #673ab7 !important;
		border-color: #673ab7 !important;
		color: white;
		padding: 9px 16px;
		border-radius: 30px;
		cursor: pointer;
		outline: 0;
	}
}

select.selectpicker {
	display: block;
	margin: 0 auto;
	padding-left: 20px;
}

.btn-new {
	background-color: #2a3f54;
}

.global-hash-title {
	font-size: 20px;
	font-weight: 600;
	margin: 30px 0;
}

.sweet-alert-confirm-button {
	color: #fff !important;
	background-color: #3f6ad8 !important;
	border-color: #3f6ad8 !important;
	padding: 10px 60px !important;
}

.sweet-alert-cancle-button {
	color: #fff !important;
	background-color: #d92550 !important;
	border-color: #d92550 !important;
	padding: 10px 60px !important;
}

.sweet-alert-wrapper {
	.lead {
		margin: 10px 0;
	}

	input {
		border-radius: 6px !important;
		font-size: 14px !important;
		border: 1px solid #ddd !important;

		&:focus {
			border: 1px solid #9a9a9a !important;
			box-shadow: none !important;
		}
	}
}

.icon-component {
	background: #eceff1;

	.icon {
		font-size: 36px;
		color: rgba(0, 0, 0, 0.54);
	}

	.text {
		color: rgba(0, 0, 0, 0.87);
		font-size: 14px;
	}
}

.profile-image {
	img {
		height: 100px;
		width: 100px;
		border-radius: 50%;
	}
}

.Profile-component {
	margin: -15px;

	.background {
		height: 250px;
	}

	.send-req-btn {
		position: absolute !important;
		top: 10px;
		right: 25px;
	}

	.profile-left-shade {
		position: relative;
		margin-top: -52px;
	}

	.profile-right-shade {
		background: white;
		position: relative;
		top: -25px;
	}

	.profile-list {
		font-weight: 600;
		font-size: 14px;
		padding-bottom: 10px;
		margin-right: 25px;
		cursor: pointer;
	}

	.feed-text-area {
		display: block;
		border-radius: 2px;
		min-width: 0;
		position: relative;
		text-decoration: none;
		transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
		box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
			0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
		background-color: #eee;
		border-color: #eee;
		padding: 16px;

		textarea {
			box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
			background: #fff;

			&:focus {
				border-color: white;
			}
		}

		.feed-text-area-icon {
			i {
				font-size: 20px;
				margin-right: 15px;
			}
		}
	}

	.feed-card {
		margin: 0 auto;
		padding: 15px;
		transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
		box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
			0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

		.feed-card-header {
			.feed-card-list-image {
				img {
					height: 40px;
					width: 40px;
					border-radius: 50%;
					margin-top: -26px;
				}
			}

			.feed-post-name {
				font-size: 15px;
				font-weight: bold;
			}

			.feed-post-time {
				font-size: 12px;
				font-weight: 300;
			}
		}

		.feed-card-body {
			.body-description {
				font-size: 14px;
				font-weight: 300;
			}

			.feed-card-media {
				img {
					width: 100%;
					height: 350px;
				}
			}
		}
	}

	.Work-header {
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
			0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
		padding: 15px 15px;
		align-items: center;
	}

	.work-card {
		box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
			0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
		margin: 10px 0;

		.work-body {
			.Work-title {
				font-size: 15px;
				font-weight: 600;
			}

			.Work-text {
				font-size: 13px;
				font-weight: 300;
			}
		}
	}

	.see-all-btn {
		font-size: 12px;
		cursor: pointer;
	}

	.friend-image {
		width: 100%;
		object-fit: cover;
	}

	.profile-media-card {
		background-color: #f5f5f5 !important;
		padding: 25px;

		.media-image {
			height: 200px;
			width: 100%;
			object-fit: cover;
		}

		.media-text {
			position: absolute;
			top: 40%;
			text-align: center;
			width: 88%;
			color: white;
			font-weight: bold;
		}
	}
}

.header-popover-width {
	min-width: 25rem;

	.custom-popover-header {
		font-weight: 600;
		padding: 20px !important;
	}
}

.language-popover-width {
	min-width: 15rem;

	.custom-popover-header {
		font-weight: 600;
		padding: 20px !important;
	}

	.language-list-hover {
		cursor: pointer;

		&:hover {
			background: rgb(250, 250, 250);
		}
	}
}

.notification-popover-profile {
	img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}
}

.bs-popover-auto[x-placement^='bottom'] .arrow,
.bs-popover-bottom .arrow {
	top: calc((0.5rem + 0px) * -1) !important;
}

.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.bs-popover-bottom .arrow::after {
	top: 1px;
	// border-bottom-color: #6200ea !important;
}

.bs-popover-auto[x-placement^='bottom'] .popover-header::before,
.bs-popover-bottom .popover-header::before {
	width: 0 !important;
}

.grid-popover {
	i {
		font-size: 2.5rem !important;
		margin: 5px 0;
		opacity: 0.6;
	}

	.grid-text {
		font-size: 15px;
		font-weight: 600;
	}

	.col-border {
		border: 1px solid rgba(117, 117, 117, 0.2);
	}
}

.grid-popover i {
	font-size: 1rem !important;
}

.ul-bottom-border {
	border: 1px solid rgba(117, 117, 117, 0.2);
}

.gauge-card {
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
		0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
	padding: 16px;
}

.rasta-stripes {
	.react-datepicker__week:nth-child(3n + 1) {
		background-color: #215005;
	}

	.react-datepicker__week:nth-child(3n + 2) {
		background-color: #eea429;
	}

	.react-datepicker__week:nth-child(3n + 3) {
		background-color: #a82a15;
	}
}

.red-border {
	border-color: #f00;
}

.calender-back-class {
	box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
	background: white;
	border-radius: 10px;
	margin: 20px 0;

	.scheduler {
		margin: 6px auto !important;
	}

	.ant-radio-button-wrapper-checked {
		background-color: #6200ea !important;

		&:hover {
			color: white !important;
		}
	}
}

.my-table-custom-class {
	background-color: white !important;

	table {
		margin-bottom: 0;
	}
}

.business-timing-modal {
	max-width: 875px !important;
}

.chatUserDisplayHideOnLg {
	min-width: 300px;

	@media (max-width: 1199.98px) {
		display: none;
	}
}

.contactLeftPanelDisplayHideOnLg {
	min-width: 300px;

	@media (max-width: 1199.98px) {
		display: none;
	}
}

.searchStyle {
	margin-left: auto;

	@media (max-width: 575.98px) {
		max-width: 100%;
	}

	.close-search {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
		color: #6c757d;
	}
}

.filterSmallScreenmenu {
	display: none;

	@media (max-width: 1199.98px) {
		display: block;
	}
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.rc-time-picker,
.rc-time-picker-input {
	height: 100% !important;
}

.rc-time-picker-input {
	font-size: 16px !important;
}

@-webkit-keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

.mini-drawer-menu-icon-hide-topbar {
	display: hide;
}

.popover-vehicle {
	font-size: 50px;
	display: flex;
	align-items: center;
	padding: 10px;
}

.vehicle-icon-marker {
	font-size: 30px !important;
}

.roe-colors {
	display: flex;
	flex-direction: row;

	.color-left-side {
		width: 130px;
	}

	.color-right-side {
		flex: 1 0 0;
		padding-left: 10px;
	}
}

.react-strap-doc {
	padding: 0 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
	.profile-left-shade {
		margin-top: -200px !important;
	}

	.profile-right-shade {
		top: 0 !important;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767.98px) {
	.profile-left-shade {
		margin-top: -200px !important;
	}

	.profile-right-shade {
		top: 0 !important;
	}

	.drawer-handle-arrow-hide-topbar {
		display: none;
	}

	.mini-drawer-menu-icon-hide-topbar {
		display: block;
	}
}

@media only screen and (max-width: 575.98px) {
	.profile-left-shade {
		margin-top: -193px !important;
	}

	.profile-right-shade {
		top: 0 !important;
	}

	.send-req-btn {
		font-size: 12px;
	}

	.Profile-component {
		margin: -50px 0 -24px 0;
	}

	.display-1 {
		font-size: 3.5rem !important;
	}

	.display-2 {
		font-size: 3rem !important;
	}

	.display-3 {
		font-size: 2.5rem !important;
	}

	.display-4 {
		font-size: 2rem !important;
	}

	.feed-card-media {
		img {
			height: 250px !important;
		}
	}

	.react-strap-doc {
		padding: 0 15px;
	}

	.header-popover-width {
		min-width: 22rem;
	}

	.drawer-handle-arrow-hide-topbar {
		display: none;
	}

	.mini-drawer-menu-icon-hide-topbar {
		display: block;
	}

	.pa-0-small-screen {
		padding-right: 0px !important;
		padding-left: 0px !important;
	}
}

.mobile-ma-8 {
	@media (max-width: 575.98px) {
		margin-top: 12px;
	}
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

body {
	.tooltip-inner {
		margin-top: -13px;
		max-width: 17rem;
	}

	animation: fadeInAnimation ease 3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;

	.cancelButtonFullWidth {
		background: #ffffff;
		width: 100%;
		height: 65px;
		font-family: 'montserrat-bold';
		font-size: 22px;
		border: 1px solid #f95c69;
		border-radius: 0;
		color: #f95c69;

		@media (max-width: 600px) {
			height: 43.8px;
			font-size: 12px;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			height: 71.2px;
			font-size: 16.5px;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			height: 53.4px;
			font-size: 12.3px;
		}
	}
	.cancelButtonFullWidth:hover {
		background: #ffffff;
		border: 1px solid #f95c69;
		color: #f95c69;
	}
	.cancelButtonFullWidth:active {
		background: #ffffff !important;
		border: 1px solid #f95c69 !important;
		color: #f95c69 !important;
	}

	.pos-b-ne-1 {
		bottom: -1px;
	}
	.newThemeButton {
		background: linear-gradient(
			90deg,
			#ff6895aa,
			#ff7885aa,
			#ff9568ac,
			#ff9964ac
		) !important;
		width: fit-content;
		// height: 95px;
		height: 65px;
		font-family: 'montserrat-bold';
		// font-size: 22px;
		font-size: 16px;
		border: none;
		border-radius: 0;

		@media (max-width: 600px) {
			height: 43.8px;
			font-size: 12px;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			height: 71.2px;
			font-size: 16.5px;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			height: 53.4px;
			font-size: 12.3px;
		}
	}

	.newThemeButtonFullWidth {
		background: linear-gradient(
			90deg,
			#ff6895aa,
			#ff7885aa,
			#ff9568ac,
			#ff9964ac
		) !important;
		width: 100%;
		height: 65px;
		font-family: 'montserrat-bold';
		font-size: 16px;
		border: none;
		border-radius: 0;

		@media (max-width: 600px) {
			height: 43.8px;
			font-size: 12px;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			height: 71.2px;
			font-size: 16.5px;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			height: 53.4px;
			font-size: 12.3px;
		}
	}

	.outlineWrraper {
		background: linear-gradient(
			180deg,
			rgba(249, 92, 105, 0.492274) 0%,
			rgba(255, 149, 104, 0.498779) 100%
		) !important;
		width: 100%;
		display: flex;
		padding: 3px;
		cursor: pointer;
		// height: 95px;
		height: 65px;

		.button {
			user-select: none;
			background: #ffffff;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 12px 16px !important;
			font-family: 'montserrat-bold';
			// font-size: 22px;
			font-size: 16px;
		}

		@media (max-width: 600px) {
			height: 43.8px;

			.button {
				font-size: 12px;
			}
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			height: 71.2px;

			.button {
				font-size: 16.5px;
			}
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			height: 53.4px;

			.button {
				font-size: 12.3px;
			}
		}
	}

	.formWrapper {
		flex: 1;
		background-color: #fff;
		color: #313132;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media (max-width: 600px) {
			.formWrapper {
				padding: 0 20px !important;
			}
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			.formWrapper {
				padding: 0 50px !important;
			}
		}
	}

	.imageWrapper {
		flex: 1;
		display: flex;
		background: #fff !important;

		@media (max-width: 600px) {
			.imageWrapper {
				display: none;
			}
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			.imageWrapper {
				display: none;
			}
		}
	}

	.password-suggestion {
		font-size: 20px;
		margin-top: 1%;
		font-family: 'poppins-regular';
		@media (max-width: 600px) {
			font-size: 9.2px;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			font-size: 14px;
			margin-bottom: 4%;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			font-size: 11.25px;
		}
	}

	.inputContainWrraper {
		padding-bottom: 50px;

		@media (max-width: 600px) {
			padding-bottom: 15px;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			padding-bottom: 30px;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			padding-bottom: 20px;
		}
	}

	.stripeHeading {
		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			max-width: 600px !important;
			font-size: 29px !important;
		}
	}

	.headingStyle {
		padding-top: 30px;
		font-size: 42px;
		font-weight: 700;
		font-family: 'montserrat-bold' !important;
		color: #313132eb;
		padding-bottom: 1%;

		@media (max-width: 600px) {
			padding-top: 4%;
			font-size: 22px;
			padding-bottom: 2%;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			font-size: 31.5px;
			padding-bottom: 2%;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			padding-top: 30px;
			font-size: 23.6px;
		}
	}

	.maxHeading {
		max-width: 884px;

		@media (max-width: 600px) {
			max-width: 408.4px;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			max-width: 663px;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			max-width: 497.25px;
		}
	}
	.tooltip-style {
		.tooltip {
			.tooltip-inner {
				max-width: 27rem !important;
				font-size: 16px !important;
				text-align: left !important;
				@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
					font-size: 10px !important;
				}
				@media (max-width: 600px) {
					max-width: 408.4px;
				}

				@media only screen and (min-width: 600px) and (max-width: 960px) {
					max-width: 663px;
				}
			}
		}
	}
	.switch-align {
		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			justify-content: center !important;
		}
	}
	.eye-icon-size {
		@media (min-width: 1920px) and (max-width: 2560px) {
			//insert styles here...
			height: 30px;
			width: 30px;
		}
	}
	.valid-icon-size {
		@media (min-width: 1920px) and (max-width: 2560px) {
			//insert styles here...
			height: 25px;
			width: 25px;
		}
	}
	.newThemeCheckbox {
		border-radius: 2px !important;
		border: 2px solid rgba(0, 0, 0, 0.25) !important;
		float: unset !important;
		margin-left: unset !important;
		margin-top: unset !important;
		vertical-align: unset !important;
	}
	.newThemeCheckbox:focus {
		box-shadow: none !important;
		outline: none !important;
		border-color: #00000040 !important;
	}
	.newThemeCheckbox:checked {
		background-color: #f95c69 !important;
		border: 0px !important;
		box-shadow: none !important;
		&:active,
		&:focus {
			border: 0px !important;
		}
	}
	.newThemeCheckbox:active,
	.newThemeCheckbox:focus {
		box-shadow: none !important;
		outline: none !important;
		border-color: #00000040 !important;
	}

	// new switch

	.switch {
		position: relative;
		display: inline-block;
		width: 54%;
		height: 34px;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: '';
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: rgb(0, 204, 50);
	}

	input:focus + .slider {
		box-shadow: 0 0 1px rgb(0, 204, 50);
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(53px);
		-ms-transform: translateX(53px);
		transform: translateX(53px);
	}

	.openSwitchText {
		font-weight: 800;
		color: #ffffff;
		font-size: 12.3px;
		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		}
	}
	.closeSwitchText {
		font-weight: 800;
		color: #ffffff;
		font-size: 12.3px;
		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		}
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}

	.error {
		padding-top: 6px;
		font-family: 'montserrat-semi-bold';
		font-size: 18px;
		color: #ff5f5f;

		@media (max-width: 600px) {
			font-size: 10px;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			font-size: 12px;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			font-size: 10px;
		}
	}

	.borderRightRemove input {
		border-right: 0 !important;
	}
	.commonLabel {
		margin: 0 !important;
		font-size: 24px !important;
		font-family: 'poppins-regular' !important;

		@media (max-width: 600px) {
			font-size: 11px !important;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			font-size: 18px !important;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			font-weight: 400 !important;
			font-size: 13.5px !important;
		}
	}

	.confirmModalPLR {
		padding-left: 25px !important;
		padding-right: 25px !important;
	}

	@media (max-width: 600px) {
		.pr-00 {
			padding-right: 0 !important;
		}
	}

	.w-fit-content {
		width: fit-content !important;
	}
}
.mb-none {
	margin-bottom: 0% !important;
}

.accordion-button:not(.collapsed) {
	color: #000000 !important;
	background-color: transparent !important;
	box-shadow: none !important;
}

.accordion-button:focus {
	z-index: 3;
	outline: 0;
	box-shadow: none !important;
}
.accordion-button:not(.collapsed)::after {
	background-image: url('../images/accr-icon.svg') !important;
	transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
	background-image: url('../images/accr-icon.svg') !important;
}

.accordion-item {
	border-radius: 0% !important;
}

.accordion {
	--bs-accordion-btn-icon-width: 2.25rem !important;
	@media (max-width: 600px) {
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
	}

	@media only screen and (max-width: 1224px) {
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		--bs-accordion-btn-icon-width: 1.5rem !important;
	}
}

.inputField {
	width: '100%' !important;
}
.chips {
	border-radius: '2px';
	background: #ff5454 !important;
}
.optionContainer {
	// To change css for option container
	border: 0.2px solid #31313259 !important;
}
.option {
	font-size: 11px !important;
}
.chip {
	font-size: 12px !important;
	font-weight: 400 !important;
}

.searchWrapper {
	width: 100%;
	height: 77px;
	padding-inline: 16px;
	color: #313132eb !important;
	font-family: 'poppins-regular';
	font-size: 24px;
	border: 2px solid #31313259 !important;
	outline: none;
	border-radius: 0 !important;
	border-radius: 0px !important;

	@media (max-width: 600px) {
		height: 45px;
		font-size: 12px !important;
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		height: 57.7px;
		font-size: 18px !important;
	}

	@media only screen and (max-width: 1224px) {
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		height: 46.3px;
		font-size: 13.5px !important;
	}
}

.highlightOption {
	background: #ff5454 !important;
	color: #fff;
}

.multiSelectContainer li:hover {
	background: #ff5454 !important;
}

.custom-textarea {
	width: 100%;
	padding-inline: 16px;
	color: #313132eb !important;
	font-family: 'poppins-regular';
	font-size: 24px !important;
	border: 2px solid #31313259 !important;
	outline: none;
	border-radius: 0 !important;
	box-shadow: none !important;
	resize: none !important;

	::placeholder {
		color: #31313261 !important;
	}

	@media (max-width: 600px) {
		font-size: 12px !important;
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		font-size: 18px !important;
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		font-size: 13.5px !important;
	}
}

.custom-dd {
	.css-qbdosj-Input {
		color: #313132eb !important;
		font-family: poppins-regular !important;
		font-weight: 600 !important;
		box-shadow: none !important;
	}
	.css-t3ipsp-control:hover {
		border-color: transparent !important;
		box-shadow: none !important;
	}

	.css-lw1qjv-control {
		font-size: 24px !important;
		color: #313132eb !important;

		height: 65px !important;
		@media (max-width: 600px) {
			height: 44px !important;
			font-size: 12px !important;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			height: 56px !important;
			font-size: 18px !important;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			height: 45px !important;
			font-size: 13.5px !important;
		}
	}
	.css-lw1qjv-control:hover {
		border: none !important;
	}
	.css-1o2bcxn-ValueContainer {
		font-size: 24px !important;
		font-weight: 500 !important;
		height: 65px !important;
		font-family: poppins-regular !important;

		@media (max-width: 600px) {
			height: 44px !important;
			font-size: 12px !important;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			height: 56px !important;
			font-size: 18px !important;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			height: 45px !important;
			font-size: 13.5px !important;
		}
	}

	.css-13cymwt-control {
		height: 65px !important;
		border: 0px !important;
		border-radius: 0px !important;
		font-family: poppins-regular !important;
		box-shadow: none !important;
		font-size: 24px !important;
		color: #313132eb !important;
		min-height: auto !important;

		font-weight: 600 !important;
		@media (max-width: 600px) {
			height: 44px !important;
			font-size: 12px !important;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			height: 56px !important;
			font-size: 18px !important;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			height: 45px !important;
			font-size: 13.5px !important;
		}
	}
}

.manageMenuOffcanvas {
	width: 60% !important;
	@media (max-width: 600px) {
		width: 100% !important;
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		width: 100% !important;
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		width: 60% !important;
	}
}
