.background-transparent {
	background: transparent !important;
}

@mixin ele-font($name) {
	font-family: $name;
}

.text-justify {
	text-align: justify !important;
}

@for $i from 0 through 100 {
	.border-radius-#{$i} {
		border-radius: $i * 1px !important;
	}

	.fs-#{$i} {
		font-size: $i * 1px !important;
		&.light-text {
			@include ele-font('montserrat-light');
		}
		&.regular-text {
			@include ele-font('montserrat-regular');
		}
		&.medium-text {
			@include ele-font('montserrat-medium');
		}
		&.semi-bold-text {
			@include ele-font('montserrat-semi-bold');
		}
		&.bold-text {
			@include ele-font('montserrat-bold');
		}
		&.segoeui-bold-text {
			@include ele-font('segoeui-bold');
		}
	}

	.w-#{$i} {
		width: $i * 1% !important;
	}

	.pa-#{$i} {
		padding: $i * 1px !important;
	}

	.pr-#{$i} {
		padding-right: $i * 1px !important;
	}

	.pl-#{$i} {
		padding-left: $i * 1px !important;
	}

	.pt-#{$i} {
		padding-top: $i * 1px !important;
	}

	.pa-t-#{$i} {
		padding-top: $i * 1px !important;
	}

	.pb-#{$i} {
		padding-bottom: $i * 1px !important;
	}

	.pa-b-#{$i} {
		padding-bottom: $i * 1px !important;
	}

	.plr-#{$i} {
		padding-left: $i * 1px !important;
		padding-right: $i * 1px !important;
	}
	.prl-#{$i} {
		padding-top: $i * 1px !important;
		padding-bottom: $i * 1px !important;
	}
	.ptb-#{$i} {
		padding-top: $i * 1px !important;
		padding-bottom: $i * 1px !important;
	}
	.pbt-#{$i} {
		padding-top: $i * 1px !important;
		padding-bottom: $i * 1px !important;
	}

	.ma-#{$i} {
		margin: $i * 1px !important;
	}

	.mr-#{$i} {
		margin-right: $i * 1px !important;
	}

	.mt-#{$i} {
		margin-top: $i * 1px !important;
	}

	.ma-t-#{$i} {
		margin-top: $i * 1px !important;
	}

	.mb-#{$i} {
		margin-bottom: $i * 1px !important;
	}

	.ma-b-#{$i} {
		margin-bottom: $i * 1px !important;
	}

	.ml-#{$i} {
		margin-left: $i * 1px !important;
	}

	.mlr-#{$i} {
		margin-left: $i * 1px !important;
		margin-right: $i * 1px !important;
	}
	.mrl-#{$i} {
		margin-left: $i * 1px !important;
		margin-right: $i * 1px !important;
	}
	.mtb-#{$i} {
		margin-top: $i * 1px !important;
		margin-bottom: $i * 1px !important;
	}
	.mbt-#{$i} {
		margin-top: $i * 1px !important;
		margin-bottom: $i * 1px !important;
	}
	.h-px-#{$i} {
		height: $i * 1px !important;
	}
	.w-px-#{$i} {
		width: $i * 1px !important;
	}
}

.mlr-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

.ml-auto {
	margin-left: auto !important;
}

.text-center {
	text-align: center !important;
}

.cursor-pointer {
	cursor: pointer;
}

.pos-relative {
	position: relative;
}

.headline {
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
	letter-spacing: normal;
}

.pull-right {
	float: right;
}

.border-none {
	border: 0 !important;
}

.border-bottom {
	border-bottom: 1px solid #ced4da;
}

.vert-middle {
	vertical-align: middle;
}

.flex {
	display: flex;
	&.center {
		align-items: center;
	}
}

.flex-end {
	display: flex;
	justify-content: flex-end;
}

.flex-between {
	display: flex;
	justify-content: space-between;
}

.flex-1 {
	flex: 1;
}

@for $i from 0 through 1000 {
	.flex-#{$i} {
		// display: flex;
		flex: $i !important;
		// min-width: 0;
		// min-height: 0;
	}
}

.fill-width {
	width: 100%;
}

.flex-vertical {
	display: flex;
	align-items: center;
}

.border-bottom {
	border-top: 1px solid #e9ecef;
}

.contentModal {
	max-height: 90vh !important;
	.modal-content {
		border: 0px;
		background: #ffffff;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
		border-radius: 16px;
		@media only screen and (max-width: 768px) {
			.content-title {
				font-size: 28px !important;
			}
			.content-text {
				font-size: 14px !important;
			}
		}
	}
}

.themeText {
	color: #242424 !important;
}

.themeLinkText {
	color: #f94d73 !important;
}

.minHeight400 {
	min-height: 400px;
}

@for $i from 0 through 1000 {
	.right-#{$i} {
		right: $i * 1px !important;
	}
	.left-#{$i} {
		left: $i * 1px !important;
	}
	.top-#{$i} {
		top: $i * 1px !important;
	}
	.bottom-#{$i} {
		bottom: $i * 1px !important;
	}
	.zIndex-#{$i} {
		z-index: $i !important;
	}
}
.todoPopover {
	.popover {
		z-index: 1 !important;
	}
	.popover-inner {
		position: absolute;
		right: -20px;
	}
	.popover-arrow {
		transform: translate(-85px, 0px) !important;
		@media only screen and (max-width: 575.98px) {
			transform: translate(-80px, 0px) !important;
		}
	}
}

.Profile-Popover {
	.popover {
		z-index: 110 !important;
		.list-inline {
			min-width: 150px;
		}
	}
	.popover-arrow {
		transform: translate3d(100px, 0px, 0px) !important;
		@media only screen and (max-width: 575.98px) {
			transform: translate3d(110px, 0px, 0px) !important;
		}
	}
}

.Order-Popover {
	.popover {
		z-index: 1 !important;
	}
	.popover-arrow {
		transform: translate3d(100px, 0px, 0px) !important;
		@media only screen and (max-width: 575.98px) {
			transform: translate3d(110px, 0px, 0px) !important;
		}
	}
}

.Venue-Popover {
	.popover {
		z-index: 1 !important;
	}
	.popover-arrow {
		transform: translate3d(100px, 0px, 0px) !important;
		@media only screen and (max-width: 575.98px) {
			transform: translate3d(110px, 0px, 0px) !important;
		}
	}
}

.Order-Popover {
	.popover {
		z-index: 1 !important;
	}
	.popover-arrow {
		transform: translate3d(100px, 0px, 0px) !important;
		@media only screen and (max-width: 575.98px) {
			transform: translate3d(110px, 0px, 0px) !important;
		}
	}
}

.popover {
	background: #ffffff !important;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
	border-radius: 8px !important;
	z-index: 1 !important;
}

.verifyOtp {
	min-width: 686px;
	@media only screen and (max-width: 768px) {
		min-width: 300px;
	}
}

.confirmModal {
	min-width: 610px;
	@media only screen and (max-width: 768px) {
		min-width: 247px;
	}
}

.form-control.is-invalid {
	background-image: none !important;
}
.min-height-83 {
	min-height: 83px !important;
}
.min-height-52 {
	min-height: 52px !important;
}
.min-height-24 {
	min-height: 24px !important;
}
.min-height-38 {
	min-height: 38px !important;
}
.min-height-40 {
	min-height: 40px !important;
}
.min-height-100 {
	min-height: 100% !important;
}

.react-datepicker {
	border: 1px solid #242424 !important;
	background-color: #fff;
}
.react-datepicker__month-container {
	border: 0px !important;
	.react-datepicker__header {
		padding-top: 0px;
		border: 0px !important;
		background-color: #fff;
	}
	.react-datepicker__day-names {
		padding-top: 5px;
	}
	.react-datepicker__day-name {
		font-weight: 600;
		color: #ff5f5f !important;
	}
	.react-datepicker__header {
		margin-top: 5px;
	}
	.react-datepicker__year-dropdown-container--scroll {
		margin: 0px 25px;
	}
	.react-datepicker__month-read-view--down-arrow,
	.react-datepicker__year-read-view--down-arrow {
		top: 3px !important;
		border-color: #000 !important;
		border-width: 2px 2px 0 0 !important;
	}
	.react-datepicker__day--keyboard-selected {
		color: #242424;
		background-color: #fff;
	}
	.react-datepicker__month-read-view--selected-month,
	.react-datepicker__year-read-view--selected-year,
	.react-datepicker__month-option,
	.react-datepicker__year-option {
		color: #242424;
		background-color: #fff;
		font-weight: 600;
	}
	.react-datepicker__year-select,
	.react-datepicker__month-select {
		color: #242424;
		background-color: #fff;
		font-weight: 600;
		padding: 5px;
		border: 0px;
		outline: none;
	}
	.react-datepicker__month-read-view,
	.react-datepicker__year-read-view {
		visibility: visible !important;
	}
	.react-datepicker__month-dropdown,
	.react-datepicker__year-dropdown {
		background-color: #fff;
	}
	.react-datepicker__day--selected,
	.react-datepicker__day--in-selecting-range,
	.react-datepicker__day--in-range {
		background-color: #ff5f5f !important;
		color: #fff !important;
		border-radius: 50%;
	}
	.react-datepicker__day {
		color: #242424;
	}
}
.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before {
	border-color: #000 !important;
	top: 20px;
}
.react-datepicker__current-month--hasMonthDropdown {
	display: none;
}
.react-datepicker__current-month {
	color: #242424 !important;
}
.cursor-not-allowed {
	cursor: not-allowed !important;
}
.react-datepicker-popper {
	z-index: 9999;
}
.tooltip-inner {
	border-radius: 3px !important;
	margin-top: 1px;
}
.outline-none {
	outline: none !important;
}
.placeholder-center::placeholder {
	/* Firefox, Chrome, Opera */
	text-align: center;
}
.box-shadow-none {
	box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
